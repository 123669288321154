import React from 'react';
import { useSnackbar } from 'notistack';
import './CartList.css';
import { useDispatch, useSelector } from 'react-redux';
import thousandSeparator from '../../utils/thousandSeparator';
import { addToCart, removeFromCart } from '../../actions/customerActions';
import {
  BUY_TYPE_RAPPI,
  BUY_TYPE_CORNERSHOP,
  BUY_TYPE_UBEREATS,
  BUY_TYPE_PEDIDOSYA,
  BUY_TYPE_RAPPI_TURBO,
  BUY_TYPE_GOODMEAL,
  BUY_TYPE_RAPPI_PAYLESS,
} from '../../utils/const';
import { getPrice } from '../../utils/getPrice';
import track from '../../utils/analytics';

function CartList({
  scrolled,
  cart,
  buyType,
  rappiPriceList,
  rappiTurboPriceList,
  cornershopPriceList,
  ubereatsPriceList,
  pedidosyaPriceList,
  goodmealPriceList,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    cart_loading: cartLoading,
    user,
    recommended_product: recommendedProductId,
  } = useSelector((state) => state.customer);

  const addOne = async (id) => {
    if (cartLoading) return;
    if (cart[id].amount <= 999) {
      if (cart[id].product.sellout.id && cart[id].product.sellout.stock > 0) {
        if (cart[id].amount + 1 <= cart[id].product.sellout.stock) {
          dispatch(
            addToCart(
              cart[id].product,
              1,
            ),
          );
          const key = enqueueSnackbar(`Se agrego 1 ${cart[id].product.name} al carro`, {
            variant: 'success',
            onClick: () => {
              closeSnackbar(key);
            },
          });

          if (cart[id].product.id === recommendedProductId) {
            // GA
            track.event('add_to_cart', {
              items: [{
                item_id: cart[id].product.sku,
                item_name: cart[id].product.name,
                creative_name: `Oferta perzonalizada ${cart[id].product.sku}`,
                creative_slot: `Usuario ${user.rut}`,
                quantity: 1,
              }],
            });
          }
        }
      } else if (cart[id].amount + 1 <= cart[id].product.stock) {
        dispatch(addToCart(cart[id].product, 1));
        const key = enqueueSnackbar(`Se agrego 1 ${cart[id].product.name} al carro`, {
          variant: 'success',
          onClick: () => {
            closeSnackbar(key);
          },
        });

        if (cart[id].product.id === recommendedProductId) {
          // GA
          track.event('add_to_cart', {
            items: [{
              item_id: cart[id].product.sku,
              item_name: cart[id].product.name,
              creative_name: `Oferta personalizada ${cart[id].product.sku}`,
              creative_slot: `Usuario ${user.rut}`,
              quantity: 1,
            }],
          });
        }
      } else if (cart[id].amount > 999) {
        const key = enqueueSnackbar('No puede agregar mas de 999 productos', {
          variant: 'warning',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      } else if (cart[id].amount + 1 > cart[id].product.stock) {
        const key = enqueueSnackbar('No hay suficiente stock', {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      }
    }
  };

  const subtractOne = async (id) => {
    if (cartLoading) return;
    if (cart[id].amount > 1) {
      dispatch(addToCart(cart[id].product, -1));
      const key = enqueueSnackbar(`Se eliminó 1 ${cart[id].product.name} del carro`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (cart[id].amount === 1) {
      dispatch(removeFromCart(id));

      const key = enqueueSnackbar(`Se eliminó ${cart[id].product.name} del carro`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  const removeProductFromCart = (id) => {
    if (cartLoading) return;
    dispatch(removeFromCart(id));
    const key = enqueueSnackbar(`Se eliminó ${cart[id].product.name} del carro`, {
      variant: 'success',
      onClick: () => {
        closeSnackbar(key);
      },
    });
  };

  const renderUnitPrice = (item) => {
    if (buyType === BUY_TYPE_RAPPI) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, rappiPriceList), '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_RAPPI_PAYLESS) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, rappiPriceList), '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_RAPPI_TURBO) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, rappiTurboPriceList), '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_CORNERSHOP) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, cornershopPriceList), '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_UBEREATS) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, ubereatsPriceList), '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_PEDIDOSYA) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, pedidosyaPriceList), '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_GOODMEAL) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, goodmealPriceList), '.')}
          </p>
        </div>
      );
    }

    if (!item.prices) {
      dispatch(removeFromCart(item.product.id));
      return (<div />);
    }

    if (item.prices?.normal) {
      let totalDiscount = item.discounts?.discountTotal;
      if (totalDiscount) totalDiscount = Math.round(totalDiscount / item.amount);

      return (
        <div>
          <div className="price">
            <p>
              $
              {thousandSeparator(item.prices.normal.price, '.')}
            </p>
          </div>
        </div>
      );
    }

    return (<div />);
  };

  const renderTotalPrice = (item) => {
    if (buyType === BUY_TYPE_RAPPI) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, rappiPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_RAPPI_PAYLESS) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, rappiPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_RAPPI_TURBO) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, rappiTurboPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_CORNERSHOP) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, cornershopPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_UBEREATS) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, ubereatsPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_PEDIDOSYA) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, pedidosyaPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (buyType === BUY_TYPE_GOODMEAL) {
      return (
        <div className="price">
          <p>
            $
            {thousandSeparator(getPrice(item.product.prices, goodmealPriceList) * item.amount, '.')}
          </p>
        </div>
      );
    }

    if (!item.prices) {
      dispatch(removeFromCart(item.product.id));
      return (<div />);
    }

    if (item.prices?.normal) {
      const totalPrice = item.prices.normal.price * item.amount;
      return (
        <div>
          <div className="price">
            <p>
              $
              {thousandSeparator(totalPrice, '.')}
            </p>
          </div>
        </div>
      );
    }

    return (<div />);
  };

  const createRows = () => Object.keys(cart).map((id) => (
    <div>
      <div className="info">
        <p>{cart[id].product.name}</p>
      </div>
      <div className="amount-cell-cart">
        <button type="button" onClick={() => subtractOne(id)}>
          -
        </button>
        <div>{cart[id].amount}</div>
        <button type="button" onClick={() => addOne(id)}>
          +
        </button>
      </div>
      {renderUnitPrice(cart[id])}
      {renderTotalPrice(cart[id])}
      <button
        type="button"
        className="remove-product"
        onClick={() => removeProductFromCart(id)}
      >
        <img src="img/close.png" alt="X" />
      </button>
    </div>
  ));

  return (
    <div className="cart-list-table">
      <div className={scrolled ? 'fixed' : 'scrollable'}>
        <div className="info">Nombre</div>
        <div className="amount-cell-cart">#</div>
        <div className="price">$ Unidad</div>
        <div className="price">$ Total</div>
        <div className="remove-product" />
      </div>
      <hr className={scrolled ? 'fixed-cart-hr' : 'scrollable-cart-hr'} />
      {createRows()}
    </div>
  );
}

export default CartList;
