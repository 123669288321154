import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { CircularProgress } from '@material-ui/core';
import Page from '../../components/Layout/Page';
import axios from '../../utils/axios';
import {
  DOCUMENT_ENTRY_TYPE_FACTURA,
  DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO,
  FINISHED_PURCHASE_INVOICE,
} from '../../utils/const';
import { createEnteredPrint } from '../../utils/addstock';
import './AddStock.css';

function AddStockSelect() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const invoiceInput = useRef(null);

  // Redux states
  const { user } = useSelector((state) => state.account);
  const { machineNumber } = useSelector((state) => state.session);

  // React states
  const [type, setType] = useState(DOCUMENT_ENTRY_TYPE_FACTURA);
  const [documentNumber, setDocumentNumber] = useState('');
  const [documentList, setDocumentList] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState('0');
  const [rePrint, setRePrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  window.callPrinterWithResponse = async (resp) => {
    if (resp?.status === 0) {
      setRePrint(false);
      setDocumentNumber('');
    } else if (resp?.status === 2) {
      const errorMessage = resp.error || 'Falta papel en la impresora';
      const fullErrorMessage = `Error al imprimir. Por favor intente nuevamente. (${errorMessage})`;
      const key = enqueueSnackbar(fullErrorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (resp?.status === 9) {
      const errorMessage = resp.error || 'Batería muy baja para imprimir';
      const fullErrorMessage = `Error al imprimir. Por favor intente nuevamente. (${errorMessage})`;
      const key = enqueueSnackbar(fullErrorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      const errorMessage = resp.error || 'Ha ocurrido un error al imprimir';
      const fullErrorMessage = `Error al imprimir. Por favor intente nuevamente. (${errorMessage})`;
      const key = enqueueSnackbar(fullErrorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    setPrintLoading(false);
  };

  window.requestForPrinterResult = async (resp) => {
    if (resp?.response?.status === 0) {
      setRePrint(false);
      setDocumentNumber('');
    } else if (resp?.response?.codeResponse === 2) {
      let errorMessage = resp.response?.glossResponse || 'Falta papel en la impresora';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (resp?.response?.codeResponse === 9) {
      let errorMessage = resp.response?.glossResponse || 'Batería muy baja para imprimir';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      let errorMessage = resp?.response?.glossResponse || 'Ha ocurrido un error al imprimir';
      if (resp?.response?.codeResponse) {
        errorMessage += `(Cod ${resp.response.codeResponse})`;
      }
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    setPrintLoading(false);
  };

  useEffect(() => {
    const getDocumentList = async () => {
      try {
        if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
          // Get purchase orders
          const { data } = await axios.get(`/api/purchase-order/ingress/${user.store_id}`);
          if (data.success) {
            setDocumentList(data.data);
          }
        }
        if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
          // Get store orders
          const { data } = await axios.get(`/api/store-order/ingress/${user.store_id}`);
          if (data.success) {
            setDocumentList(data.data);
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.errorMessage || error.message;
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      }
    };

    getDocumentList();
  }, [type]);

  const handleOptionChange = (event) => {
    setDocumentNumber('');
    setRePrint(false);
    setSelectedDocumentId(event.target.value);
  };

  const createOptions = () => {
    let defaultText = 'SELECCIONAR ORDEN DE COMPRA';
    if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
      defaultText = 'SELECCIONAR GUÍA DE DESPACHO';
    }

    const options = [(<option key="0" value="0">{defaultText}</option>)];

    documentList.forEach((document) => {
      let number = null;
      if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
        number = document.purchase_order_number;
      } else {
        number = document.store_order_number;
      }

      if (number) {
        const formatedDate = moment(document.created_at).format('DD/MM/YYYY');
        options.push(
          <option
            key={`${document.id}-${number}`}
            value={document.id}
          >
            {`${formatedDate} - ${number} - ${document.name}`}
          </option>,
        );
      }
    });
    return options;
  };

  const handleIngressDocument = async () => {
    try {
      if (!selectedDocumentId || selectedDocumentId === '0') {
        const key = enqueueSnackbar('Favor seleccione una opción del listado', {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
        return null;
      }

      setLoading(true);
      // Get document status
      if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
        // Get the purchase invoice detail. If does not exist, a new one is created
        const { data } = await axios.post('/api/purchase-invoice/entry', {
          invoice_number: documentNumber,
          status: 'INICIADA',
          purchase_order_id: selectedDocumentId,
        });

        if (data.success) {
          // Redirect
          const purchaseInvoiceId = data.data.purchaseInvoice.id;
          window.location.assign(`/ingresar-stock/factura/${purchaseInvoiceId}`);
        }
      }
      if (type === DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO) {
        window.location.assign(`/ingresar-stock/guia-de-despacho/${selectedDocumentId}`);
      }
      return null;
    } catch (error) {
      const errorMessage = error.response?.data?.errorMessage || error.message;
      const purchaseInvoiceStatus = error.response?.data?.purchaseInvoiceStatus || null;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      if (purchaseInvoiceStatus === FINISHED_PURCHASE_INVOICE) {
        setRePrint(true);
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  const printEnteredProducts = async () => {
    let machineType = 'redelcom';
    if (window.AppPagoSDK) machineType = 'smartPOS';

    setPrintLoading(true);
    const printResponse = await createEnteredPrint({
      type,
      machineType,
      documentId: selectedDocumentId,
      machineNumber,
      documentNumber,
    });
    setPrintLoading(false);

    if (!printResponse.success) {
      const key = enqueueSnackbar(printResponse.errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else if (rePrint) setRePrint(false);
  };

  return (
    <Page
      title="Club Líquidos | POS Ingreso"
      progressStep="none"
      backRef="/inicio"
    >
      <div className="addstock-buy-container-stocks">
        <img style={{ width: '200px' }} src="img/logo-liquidos-blanco.png" alt="logo" />
        <h3>Seleccionar documento</h3>
        <select
          className="addstock-order-list-select"
          value={type}
          onChange={(e) => {
            setType(e.target.value);

            // Reset document number
            setDocumentNumber('');
          }}
        >
          <option value={DOCUMENT_ENTRY_TYPE_FACTURA}>Factura</option>
          <option value={DOCUMENT_ENTRY_TYPE_GUIA_DE_DESPACHO}>Guía de Despacho</option>
        </select>
        {type === DOCUMENT_ENTRY_TYPE_FACTURA ? (
          <div>
            <h4 style={{ textAlign: 'center' }}>Seleccionar orden de compra de la factura</h4>
          </div>
        ) : (
          <div>
            <h4 style={{ textAlign: 'center' }}>Seleccionar guía de despacho</h4>
          </div>
        )}
        <select
          className="addstock-order-list-select"
          onChange={(event) => handleOptionChange(event)}
          value={selectedDocumentId}
        >
          {createOptions()}
        </select>
        <div className="addstock-row-elements">
          {type === DOCUMENT_ENTRY_TYPE_FACTURA && (
            <input
              className="addstock-input-invoice"
              ref={invoiceInput}
              id="invoice"
              type="number"
              name="invoice"
              placeholder="Número de factura"
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
            />
          )}
          <button
            className="addstock-yellow-button"
            type="submit"
            onClick={() => handleIngressDocument()}
            disabled={loading}
          >
            {loading
              ? <CircularProgress style={{ color: 'black', width: '15px', height: '15px' }} />
              : (
                <span>{type === DOCUMENT_ENTRY_TYPE_FACTURA ? 'Ingresar Factura' : 'Ingresar Despacho'}</span>
              )}
          </button>
        </div>
        {rePrint ? (
          <button
            className="addstock-yellow-button"
            type="button"
            onClick={() => {
              printEnteredProducts();
            }}
          >
            {printLoading
              ? (<CircularProgress style={{ color: 'black' }} />)
              : 'Re imprimir lo ingresado'}
          </button>
        ) : <div style={{ height: '60px' }} />}
      </div>
    </Page>
  );
}

export default AddStockSelect;
