/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
// import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router';
import Page from '../../components/Layout/Page';
import './Profile.css';
import SplashScreen from '../../components/Loading/SplashScreen';
import axios from '../../utils/axios';
import thousandSeparator from '../../utils/thousandSeparator';
import {
  resetCustomerOrderId,
  // setOrderStatus,
  setCustomerPoints,
  // addToCart,
  // addRecommendedProduct,
  resetCustomerOrderNumber,
  resetOrderStatus,
} from '../../actions/customerActions';
// import {
//   STATUS_PENDIENTE,
// } from '../../utils/const';
// import { getSalePrices } from '../../utils/getPrice';
// import track from '../../utils/analytics';
import { STATUS_BILLING_PROCESSING } from '../../utils/const';

function Profile() {
  const [totalPoints, setTotalPoints] = useState(0);
  // const [lastProducts, setLastProducts] = useState([]);
  // const [recommendedProduct, setRecommendedProduct] = useState(null);
  // const [walletCash, setWalletCash] = useState(0);
  const dispatch = useDispatch();
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const cart = useSelector((state) => state.customer.cart);
  const customer = useSelector((state) => state.customer);
  // const store = useSelector((state) => state.session.store);
  // const buyType = customer.type;
  const { machineNumber } = useSelector((state) => state.session);

  const [loading, setLoading] = useState(true);

  // const [quantity, setQuantity] = useState(1);

  // const increaseQty = () => {
  //   if (recommendedProduct.sellout.id && recommendedProduct.sellout.stock > 0) {
  //     if (quantity + 1 <= recommendedProduct.sellout.stock) {
  //       setQuantity(quantity + 1);
  //     }
  //   } else if (quantity + 1 <= recommendedProduct.stock) {
  //     setQuantity(quantity + 1);
  //   }
  // };

  // const decreaseQty = () => {
  //   if (quantity - 1 > 1) {
  //     setQuantity(quantity - 1);
  //   } else {
  //     setQuantity(1);
  //   }
  // };

  const submitBuy = () => {
    window.location.href = '/compra';
  };

  // const checkStock = (product, amount) => {
  //   if (!(product.id in cart)) {
  //     if (product.sellout.id && product.sellout.stock > 0) {
  //       if (amount <= product.sellout.stock) {
  //         return true;
  //       }
  //     } else if (amount <= product.stock) {
  //       return true;
  //     }
  //   }

  //   if (product.id in cart) {
  //     if (product.sellout.id && product.sellout.stock > 0) {
  //       if ((cart[product.id].amount + amount) <= product.sellout.stock) {
  //         return true;
  //       }
  //     } else if ((cart[product.id].amount + amount) <= product.stock) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  // const addProductToCart = () => {
  //   if (!checkStock(recommendedProduct, quantity)) {
  //     const key = enqueueSnackbar(`${recommendedProduct.name} no tiene stock suficiente!`, {
  //       variant: 'error',
  //       onClick: () => {
  //         closeSnackbar(key);
  //       },
  //     });
  //   } else {
  //     // Get prices
  //     const prices = getSalePrices(recommendedProduct, store, buyType);
  //     // Get max quantities
  //     const maxQuantities = {
  //       clubGeneral: {
  //         min: 0,
  //         max: recommendedProduct.customer_limit_discount,
  //         disc: recommendedProduct.customer_discount,
  //       },
  //       bulk: {
  //         min: recommendedProduct.bulk_amount,
  //         max: 99999,
  //       },
  //       sellout: {
  //         min: 0,
  //         max: recommendedProduct.sellout.max_quantity,
  //       },
  //       recommended: {
  //         min: 0,
  //         max: recommendedProduct.max_recommended_quantity,
  //       },
  //       offers: {
  //         min: 0,
  //         max: 0,
  //       },
  //     };
  //     dispatch(addToCart(recommendedProduct, quantity, prices, maxQuantities));
  //     const key = enqueueSnackbar(`${quantity} ${recommendedProduct.name} en carro!`, {
  //       variant: 'success',
  //       onClick: () => {
  //         closeSnackbar(key);
  //       },
  //     });

  //     // GA
  //     track.event('add_to_cart', {
  //       items: [{
  //         item_id: recommendedProduct.sku,
  //         item_name: recommendedProduct.name,
  //         creative_name: `Oferta personalizada ${recommendedProduct.sku}`,
  //         creative_slot: `Usuario ${customer.user.rut}`,
  //         quantity,
  //       }],
  //     });
  //   }
  // };

  // const getUniqueProducts = (orders) => {
  //   const uniqueProducts = [];
  //   orders.forEach((order) => {
  //     order.OrderProducts.forEach((orderProduct) => {
  //       if (!uniqueProducts.find((item) => item.sku === orderProduct.Product.sku)) {
  //         uniqueProducts.push(orderProduct.Product);
  //       }
  //     });
  //   });
  //   return uniqueProducts;
  // };

  useEffect(() => {
    const getCustomerPoints = async () => {
      try {
        const pointsResponse = await axios.get(`/api/customers/${customer.user.id}/points`);
        const { points, cash, reserved } = pointsResponse.data;
        setTotalPoints(cash + points - reserved);
      } catch (error) {
        setTotalPoints(0);
      } finally {
        setLoading(false);
      }
    };

    // const getLastsOrders = async (limit) => {
    //   const response = await axios.get(`/api/orders/customer/${customer.user.id}/last-orders/${limit}`);
    //   const { orders } = response.data;
    //   const products = getUniqueProducts(orders);
    //   setLastProducts(products);
    // };

    // const getWalletOrders = async () => {
    //   const response = await axios.get(`/api/orders/customer/${customer.user.id}/wallet-orders`);
    //   const { orders } = response.data;
    //   const totalWallet = orders.reduce((acc, order) => acc + order.Payments
    //     .reduce((acc_, payment) => acc_ + payment.amount, 0), 0);
    //   setWalletCash(totalWallet);
    // };

    const isCustomer = customer.user ? customer.user.id : false;
    if (isCustomer) {
      getCustomerPoints();

      // The intention is to get the last 3 orders with parameters
      // for another uses case or modifications.
      // getLastsOrders(20);
    }

    if (customer.order_status !== STATUS_BILLING_PROCESSING) {
      dispatch(resetCustomerOrderId());
      dispatch(resetCustomerOrderNumber());
      dispatch(resetOrderStatus());
    }
    // getWalletOrders();
  }, [customer.user]);

  // useEffect(() => {
  //   const getRecommendedProducts = async () => {
  //     try {
  //       const recommendedProductsResponse = await axios.post(`/api/customer/${customer.user.id}/recommended-products`, {
  //         store_id: store.id,
  //       });

  //       if (recommendedProductsResponse.data.success) {
  //         if (checkStock(recommendedProductsResponse.data.data.product, 1)) {
  //           setRecommendedProduct(recommendedProductsResponse.data.data.product);
  //           dispatch(addRecommendedProduct(recommendedProductsResponse.data.data.product.id));
  //         }
  //       }
  //     } catch (err) {
  //       console.log('err: ', err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   const isCustomer = customer.user ? customer.user.id : false;
  //   if (isCustomer) {
  //     getRecommendedProducts();
  //   }
  // }, []);

  useEffect(() => {
    dispatch(setCustomerPoints(totalPoints));
  }, [totalPoints]);

  if (!customer.type) {
    window.location.href = '/inicio';
    return <SplashScreen />;
  }

  if (!customer.user && customer.type !== 'rappi') {
    window.location.href = '/ingreso';
    return <SplashScreen />;
  }

  if (customer.type === 'rappi' || customer.type === 'no-socio') {
    window.location.href = '/compra';
    return <SplashScreen />;
  }

  // let price = null;
  // let clubPrice = null;
  // let selloutPrice = null;
  // let recommendedPrice = null;
  // let finalPrice = null;

  // if (recommendedProduct) {
  //   const {
  //     recommended,
  //   } = getSalePrices(recommendedProduct, store, buyType);

  //   finalPrice = recommended.price;

  //   // price = recommendedProduct.prices
  //   //   .filter((p) => p.price_list_id === store.price_list_id)[0].price;
  //   // clubPrice = recommendedProduct.prices
  //   //   .filter((p) => p.price_list_id === store.club_price_list_id)[0].price;
  //   // selloutPrice = recommendedProduct.sellout?.price;
  //   // recommendedPrice = recommendedProduct.recommended_price;

  //   // if (selloutPrice) {
  //   //   if (recommendedPrice) {
  //   //     finalPrice = Math.min(price, clubPrice, selloutPrice, recommendedPrice);
  //   //   } else {
  //   //     finalPrice = Math.min(price, clubPrice, selloutPrice);
  //   //   }
  //   // } else if (recommendedPrice) {
  //   //   finalPrice = Math.min(price, clubPrice, recommendedPrice);
  //   // } else {
  //   //   finalPrice = Math.min(price, clubPrice);
  //   // }
  // }

  if (!machineNumber && !window.AppPagoSDK) {
    return (
      <Redirect to="maquina" />
    );
  }

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | POS Perfil"
      progressStep="perfil"
      backRef="/ingreso"
    >
      <div className="profile-container">
        {loading ? (
          <div>
            <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
          </div>
        ) : (
          <div>
            <div>
              <h1>LEER AL CLIENTE:</h1>
              <p>
                Hola
                <span
                  style={{
                    color: '#E6D51B',
                    fontWeight: 'bold',
                    padding: '0px 3px',
                    textTransform: 'uppercase',
                  }}
                >
                  {`${customer.user.name || ''}`}
                </span>
                {/* ¿Todo bien con sus últimas compras? */}
              </p>
              {/* <div>
                <h3>Ultimos productos comprados:</h3>
                {lastProducts.length
                  ? lastProducts.slice(0, 3).map((p) => <p>{p.name}</p>) : <p>No hay productos</p>}
              </div> */}
              {customer.user.name && totalPoints > 500 ? (
                <div>
                  <br />
                  <p>{`¿Sabe que tiene ${thousandSeparator(totalPoints, '.')} liquipuntos que puede usar en esta compra?`}</p>
                </div>
              ) : null}
              {/* <div>
                <br />
                <p>{`¿Sabe que por ser socio ha ahorrado más de $${thousandSeparator(walletCash, '.')} en los últimos 3 meses?`}</p>
              </div> */}
              {/* {recommendedProduct && (
                <div>
                  <br />
                  <p style={{ textTransform: 'uppercase', color: '#E6D51B', fontWeight: 'bold' }}>Hoy tenemos una oferta especial para usted: </p>
                  <p>{recommendedProduct.name ? recommendedProduct.name : ''}</p>
                  <br />
                  {finalPrice && (
                    <p style={{ color: 'red', fontWeight: 'bold', fontSize: '24px' }}>{`Precio: $${thousandSeparator(finalPrice, '.')}`}</p>
                  )}
                  <div className="cart-type-buttons">
                    <div
                      className="amount-cell"
                    >
                      <button onClick={() => decreaseQty()} className="add-subtract" type="button">-</button>
                      <div>{quantity}</div>
                      <button onClick={() => increaseQty()} className="add-subtract" type="button">+</button>
                    </div>
                    <button type="button" value="normal" className="yellow-button" onClick={(e) => addProductToCart(e)}>
                      Agregar
                    </button>
                  </div>
                </div>
              )} */}
            </div>
            <div className="cart-type-buttons">
              <button type="button" value="normal" className="yellow-button" onClick={(e) => submitBuy(e)}>
                Continuar
              </button>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}

export default Profile;
