import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './SavedList.css';
import {
  setCustomerType, addToCart, setCustomerOrderId, setCustomerData, setCustomerOrderNumber,
} from '../../actions/customerActions';
import thousandSeparator from '../../utils/thousandSeparator';
import { getSalePrices } from '../../utils/getPrice';

function SavedList({
  savedOrders, scrolled,
}) {
  const dispatch = useDispatch();
  const { Store: store } = useSelector((state) => state.account.user || {});
  const buyType = useSelector((state) => state.customer.type);

  const setSavedOrder = (order) => {
    // Set customer type and info
    const customerType = order.buy_type || 'no-socio';
    dispatch(setCustomerType(customerType));
    dispatch(setCustomerData(order.Customer ? order.Customer : { rut: 'Sin RUT' }));

    // Add products to cart
    order.OrderProducts.forEach((orderProduct) => {
      // Get prices
      const prices = getSalePrices(orderProduct.Product, store, buyType);
      // Get max quantities
      const maxQuantities = {
        clubGeneral: {
          min: 0,
          max: orderProduct.Product.customer_limit_discount,
          disc: orderProduct.Product.customer_discount,
        },
        bulk: {
          min: orderProduct.Product.bulk_amount,
          max: 99999,
        },
        sellout: {
          min: 0,
          max: orderProduct.Product.sellout.max_quantity,
        },
        recommended: {
          min: 0,
          max: orderProduct.Product.max_recommended_quantity,
        },
        offers: {
          min: 0,
          max: 0,
        },
      };
      dispatch(addToCart(orderProduct.Product, orderProduct.amount, prices, maxQuantities));
    });

    // Add order id
    dispatch(setCustomerOrderId(order.id));
    dispatch(setCustomerOrderNumber(order.order_number || null));

    window.location.href = '/carro';
  };

  const createRows = () => savedOrders.map((order) => ([
    <div>
      <div className="order-number-cell">
        {moment(order.created_at).format('YYYY-MM-DD HH:mm:ss')}
      </div>
      <div className="order-number-cell">
        $
        {thousandSeparator(order.total_amount, '.')}
      </div>
      <div className="info">
        {order.Customer
          ? (
            <div>
              <p>{order.Customer.name ? order.Customer.name : 'Sin Nombre'}</p>
              <p>{order.Customer.email ? order.Customer.email : 'Sin Email'}</p>
              <p>{order.Customer.rut ? order.Customer.rut : 'Sin RUT'}</p>
            </div>
          )
          : (
            <div>
              {order.buy_type === 'rappi' ? 'Rappi' : 'Cliente no registrado'}
            </div>
          )}
      </div>
      <button type="button" className="yellow-button" onClick={() => setSavedOrder(order)}>
        Retomar
      </button>
    </div>,
    <hr />,
  ]));

  return (
    <div className="saved-orders-table">
      <h3 className="yellow-title-saved-orders">Pedidos Guardados</h3>
      <div className={scrolled ? 'fixed' : 'scrollable'}>
        <div className="order-number-cell">Creación</div>
        <div className="order-number-cell">Total</div>
        <div className="info">Cliente</div>
        <div className="button-size-spacer" />
      </div>
      <hr className={scrolled ? 'fixed-hr' : 'scrollable-hr'} />
      {createRows()}
    </div>
  );
}

export default SavedList;
