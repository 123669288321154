/* eslint-disable import/prefer-default-export */
import { DOCUMENT_ENTRY_TYPE_FACTURA, REDELCOM_PROVIDER, TRANSBANK_SMARTPOS_PROVIDER } from './const';
import axios from './axios';

const createEnteredPrint = async ({
  type,
  machineType,
  documentId,
  machineNumber,
  documentNumber,
}) => {
  try {
    if (type === DOCUMENT_ENTRY_TYPE_FACTURA) {
      let provider = '';
      let providerVersion = '';
      if (window.AppPagoSDK) {
        provider = TRANSBANK_SMARTPOS_PROVIDER;
        if (window.HARDWARESDK) {
          providerVersion = 'pro';
        } else {
          providerVersion = 'normal';
        }
      } else {
        provider = REDELCOM_PROVIDER;
      }
      const { data } = await axios.post('/api/purchase-order/print/entered', {
        purchase_order_id: documentId,
        machine_number: machineNumber,
        invoice_number: documentNumber,
        machine_type: machineType,
        provider,
        provider_version: providerVersion,
      });
      if (data.data.lines && window.AppPagoSDK) {
        if (window.HARDWARESDK) { // New sdk for printing
          window.HARDWARESDK.requestForPrinter(JSON.stringify(data.data.lines));
        } else {
          const printFormat = JSON.stringify({ lines: data.data.lines });
          window.AppPagoSDK.callPrinterWith(printFormat);
        }
      }
    } else {
      const { data } = await axios.post('/api/store-order/print/entered', {
        store_order_id: documentId,
        machine_number: machineNumber,
        machine_type: machineType,
      });
      if (data.data.lines && window.AppPagoSDK) {
        if (window.HARDWARESDK) { // New sdk for printing
          window.HARDWARESDK.requestForPrinter(JSON.stringify(data.data.lines));
        } else {
          const printFormat = JSON.stringify({ lines: data.data.lines });
          window.AppPagoSDK.callPrinterWith(printFormat);
        }
      }
    }
    return {
      success: true,
    };
  } catch (err) {
    const errorMessage = err.response?.data?.errorMessage || err.message;
    const fullErrorMessage = `Error al imprimir. Por favor intente nuevamente. (${errorMessage})`;
    return {
      success: false,
      errorMessage: fullErrorMessage,
    };
  }
};

export {
  createEnteredPrint,
};
