import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useDispatch } from 'react-redux';
import { setEANFromCamera } from '../../actions/sessionActions';

function MercadoPagoCamera() {
  const dispatch = useDispatch();

  const handleCameraClick = () => {
    dispatch(setEANFromCamera(''));
    if (window.MobileWebKit.channel.isConnected) {
      const callback = (data, error) => {
        if (error) {
          console.log('scanned error', error);
          return;
        }
        if (data) {
          dispatch(setEANFromCamera(data));
        }
      };
      // eslint-disable-next-line no-undef
      launchBarcodeReader1D(callback);
    }
  };

  if (!window.MobileWebKit.channel.isConnected) return null;

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre',
      }}
      onClick={() => handleCameraClick()}
    >
      <Typography style={{ fontSize: '14px', marginRight: '3px' }}>Código Barra</Typography>
      <CameraAltIcon />
    </Box>
  );
}

export default MercadoPagoCamera;
