/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Auth from './components/Auth/Auth';
import Routes from './Routes';
import './App.css';
import AuthService from './services/authService';
import { setEANFromCamera, setSmartPOSTerminalId } from './actions/sessionActions';

const history = createBrowserHistory();

function App() {
  ReactGA.initialize('UA-162896828-3');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  window.loadKeysResponse = (resp) => {
    if (resp.codigoRespuesta === '0') {
      document.cookie = 'smart_pos_keys=true;max-age=86400';
      if (resp.terminalId) dispatch(setSmartPOSTerminalId(resp.terminalId));
    } else {
      document.cookie = 'smart_pos_keys=false;max-age=86400';
      const key = enqueueSnackbar(`SmartPOS loadKeys Error: ${JSON.stringify(resp)}`, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  window.closeTerminalResponse = (resp) => {
    if (resp) {
      if (resp.codigoRespuesta === '0') {
        const message = resp?.glosaRespuesta || 'Cierre de caja realizado con éxito';
        const key = enqueueSnackbar(message, {
          variant: 'success',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      } else {
        let errorMessage = resp?.error || resp?.glosaRespuesta || 'Ha ocurrido un error al cerrar la caja';
        const codigoRespuesta = resp?.codigoRespuesta || '';
        if (codigoRespuesta) errorMessage += `. Código ${codigoRespuesta}`;
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      }
    }
  };

  window.totalsResponse = (resp) => {
    if (!resp) {
      const errorMessage = 'Ha ocurrido un error consultar los totales';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  window.cameraPhotoResponse = async (resp) => {
    if (!resp) {
      const errorMessage = 'Ha ocurrido un error obtener la imagen';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      dispatch(setEANFromCamera(resp));
    }
  };

  window.requestForPrinterResult = async (resp) => {
    console.log('requestForPrinterResult', JSON.stringify(resp));
  };

  window.errorResult = async (resp) => {
    const key = enqueueSnackbar(JSON.stringify(resp), {
      variant: 'error',
      onClick: () => closeSnackbar(key),
    });
  };

  window.qrResponse = async (resp) => {
    if (!resp) {
      const errorMessage = 'Ha ocurrido un error obtener la imagen';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    } else {
      const key = enqueueSnackbar(JSON.stringify(resp), {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
  };

  useEffect(() => {
    const smartPOSCookie = document.cookie.split('; ').find((row) => row.startsWith('smart_pos_keys='))?.split('=')[1];
    const smartPOSCookieBool = smartPOSCookie === 'true';
    const tokenUser = AuthService.getDecodedToken();
    if (window.SmartPOS && window.AppPagoSDK && tokenUser) {
      console.log(`smart_pos_keys -- ${smartPOSCookieBool}`);
      const { api_key: apiKey, flavor } = tokenUser.smartPOS || {};
      if (apiKey && flavor) {
        // Set Smart POS API Key and flavor
        window.SmartPOS.setApiKey(apiKey);
        window.SmartPOS.setFlavor(flavor);

        // Check load keys
        if (!smartPOSCookieBool) {
          // Request for load keys
          window.AppPagoSDK.requestForLoadKeys();
        }
      }
    }
  }, []);

  return (
    <Router history={history}>
      <Auth>
        <Routes />
      </Auth>
    </Router>
  );
}

export default App;
