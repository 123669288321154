import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import CashPayment from './CashPayment';
import WiringPayment from './WiringPayment';
import CardPayment from './CardPayment';
import RappiPayment from './RappiPayment';
import RappiPaylessPayment from './RappiPaylessPayment';
import CornershopPayment from './CornershopPayment';
import UberEatsPayment from './UberEatsPayment';
import PedidosYaPayment from './PedidosYaPayment';
import WalletPayment from './WalletPayment';
import {
  setOrderStatus,
  setPaymentLoading,
  resetCustomerType,
  resetReturnType,
  resetCustomer,
  resetReturnCode,
  resetReturnProducts,
  resetDocumentType,
  resetPayments,
  resetApplyBulk,
  resetBulk,
  resetTotal,
  resetGeneralDiscount,
  resetCouponDiscount,
  resetCoupon,
  resetSubtotal,
  resetBillingInfo,
  resetCart,
  resetCustomerOrderId,
  markSmsSent,
  setCustomerPoints,
  resetRecommendedProduct,
  resetAllDiscounts,
  resetCartLoading,
  resetCustomerOrderNumber,
  resetReturnSmartPOSTransactionId,
  resetOrderStatus,
} from '../../actions/customerActions';
import Final from './Final';
import PaymentError from './PaymentError';
import {
  PAYMENT_TYPE_RAPPI,
  PAYMENT_TYPE_CORNERSHOP,
  PAYMENT_TYPE_UBEREATS,
  PAYMENT_TYPE_PEDIDOSYA,
  PAYMENT_TYPE_TARJETA_DEBITO,
  PAYMENT_TYPE_TARJETA_CREDITO,
  PAYMENT_TYPE_TRANSFERENCIA,
  PAYMENT_TYPE_WALLET,
  PAYMENT_TYPE_OTRO,
  STATUS_PENDIENTE,
  STATUS_APROBADO,
  STATUS_RECHAZADO,
  STATUS_PAGANDO,
  PAYMENT_TYPE_EFECTIVO,
  PAYMENT_TYPE_RAPPI_TURBO,
  STATUS_ERROR,
  PAYMENT_TYPE_RAPPI_PAYLESS,
  STATUS_BILLING_PROCESSING,
  STATUS_FINISHED,
  PAYMENT_TYPE_MACH,
  PAYMENT_TYPE_REDPAY,
} from '../../utils/const';
import axios from '../../utils/axios';
import thousandSeparator from '../../utils/thousandSeparator';
import MachPayment from './MachPayment';
import RedpayPayment from './RedpayPayment';

function PaymentSequence() {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const customer = useSelector((state) => state.customer);
  const orderId = customer.order_id;
  const applyBulk = useSelector((state) => state.customer.apply_bulk);
  const orderStatus = useSelector((state) => state.customer.order_status);

  const reduxPayments = useSelector((state) => state.customer.payments);
  // Leave the wallet payment at the end
  const walletPayment = reduxPayments.find((rp) => rp.payment_type === PAYMENT_TYPE_WALLET);
  const paymentsFilter = reduxPayments.filter((rp) => rp.payment_type !== PAYMENT_TYPE_WALLET);
  if (walletPayment) paymentsFilter.push(walletPayment);

  const dispatch = useDispatch();
  const [payments, setPayments] = useState(paymentsFilter);
  const [paids, setPaids] = useState([]);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [totalsPay, setTotalsPay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorAction, setErrorAction] = useState('');
  const [errorLoading, setErrorLoading] = useState(false);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const getData = async () => {
      setErrorLoading(false);
      setLoading(true);
      try {
        // Get order
        const response = await axios.get(`/api/orders/${customer.order_id}`);
        const { data } = response;
        const apiPaid = [];
        const apiPayments = [];
        data.data.payments
          .forEach((payment) => {
            if (payment.status === STATUS_PENDIENTE
              || payment.status === STATUS_RECHAZADO) {
              const newPayment = { ...payment };
              newPayment.payed = false;
              newPayment.next = false;
              newPayment.paymentType = payment.type;
              newPayment.provider = payment.provider;
              apiPayments.push(newPayment);
            } else if (payment.status === STATUS_APROBADO) {
              const newApiPaid = { ...payment };
              newApiPaid.payed = true;
              newApiPaid.next = true;
              newApiPaid.paymentType = payment.type;
              newApiPaid.provider = payment.provider;
              apiPaid.push(newApiPaid);
            }
          });

        // Leave the wallet payment at the beginning
        const wPayment = apiPayments.find((rp) => rp.type === PAYMENT_TYPE_WALLET);
        const pFilter = apiPayments.filter(
          (rp) => rp.type !== PAYMENT_TYPE_WALLET
            && rp.status !== STATUS_RECHAZADO
            && rp.status !== STATUS_ERROR,
        );
        if (wPayment) pFilter.unshift(wPayment);

        setPayments(pFilter);
        setPaids(apiPaid);
        setTotalsPay(data.data.payments);

        // Create billing row to save invoice data
        await axios.post('/api/billing', {
          orderId: customer.order_id,
          billingType: customer.document_type,
          billing: customer.billing,
        });

        // Check for amount 0 orders
        const totalAmount = pFilter.reduce((a, b) => a + b.amount, 0);
        if (totalAmount === 0) {
          await Promise.all(pFilter.map(async (payment) => {
            await axios.patch(`/api/payments/update/${payment.id}`, {
              status: 'APROBADO',
            });
          }));
        }
      } catch (err) {
        console.log(err.response?.data?.data || err.response);
        setErrorLoading(true);
        const errorMessage = err?.response?.data?.errorMessage || err.message;
        const key = enqueueSnackbar(errorMessage, {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          },
        });
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [reload]);

  const handlePayed = (err, errAction) => {
    if (!err) {
      const newPayments = [...payments];
      newPayments[step].payed = true;
      setPayments(newPayments);

      dispatch(setOrderStatus(STATUS_PAGANDO));
      setStep(step + 1);
    } else {
      setError(err);
      setErrorAction(errAction);
    }
  };

  // SmartPOS payment response
  window.salesResponse = async (resp) => {
    if (resp?.codigoRespuesta === '0') {
      const smartPOSCode = resp.codigoAutorización || '1234';
      const smartPOSTransactionNumber = resp.numeroOperacion || null;
      const terminalID = resp.terminalID || null;
      try {
        const { data } = await axios.post('/api/payments/transbank-smartpos/new', {
          order_id: orderId,
          payment_id: payments[step].id,
          transbank_code: smartPOSCode,
          smart_pos_transaction_id: smartPOSTransactionNumber,
          terminal_id: terminalID,
        });
        const newPayment = data.data.payment;
        const newPayments = [...payments];
        newPayments[step] = {
          id: newPayment.id,
          order_id: newPayment.order_id,
          type: newPayment.type,
          amount: newPayment.amount,
          status: newPayment.status,
          deposit_id: null,
          next: false,
          payed: false,
          provider: newPayment.provider,
          paymentType: newPayment.type,
          rdc_transaction_id: null,
          smart_pos_transaction_id: smartPOSTransactionNumber,
        };
        setPayments(newPayments);
        handlePayed(null);
      } catch (err) {
        const errorMessage = error.response?.data?.errorMessage || err.message;
        const errAction = error.response?.data?.errorAction;
        if (errAction === 'reset') {
          handlePayed(errorMessage, errAction);
        } else {
          handlePayed(errorMessage);
        }
      }
    } else {
      let errorMessage = resp?.error || resp?.glosaRespuesta || 'Ha ocurrido un error con Transbank';
      const codigoRespuesta = resp?.codigoRespuesta || '';
      if (codigoRespuesta) errorMessage += `. Código ${codigoRespuesta}`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
    }
    dispatch(setPaymentLoading(false));
  };

  const displayPayment = () => {
    if (error) return <PaymentError error={error} errorAction={errorAction} />;

    if (orderStatus === STATUS_BILLING_PROCESSING
      || orderStatus === STATUS_FINISHED) {
      return <Final paids={paids} />;
    }

    if (!payments.length) {
      return (
        <div>
          <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
        </div>
      );
    }

    // Check for amount 0 orders
    const totalAmount = payments.reduce((a, b) => a + b.amount, 0);
    if (totalAmount === 0) {
      return <Final paids={paids} />;
    }

    if (step === payments.length) return <Final paids={paids} />;

    const { paymentType, payed, amount } = payments[step];
    switch (paymentType) {
      default: return (
        <CardPayment
          key={step}
          total={amount}
          type={paymentType}
          payed={payed}
          handlePayed={handlePayed}
          step={step}
          paids={paids}
          payments={payments}
          paymentStep={payments[step]}
          setPayments={setPayments}
          totalSteps={payments.length}
        />
      );
      case PAYMENT_TYPE_EFECTIVO: return (
        <CashPayment
          key={step}
          step={step}
          total={amount}
          type={paymentType}
          payed={payed}
          handlePayed={handlePayed}
          paids={paids}
          payments={payments}
          paymentStep={payments[step]}
          setPayments={setPayments}
          totalSteps={payments.length}
        />
      );
      case PAYMENT_TYPE_TRANSFERENCIA: return (
        <WiringPayment
          key={step}
          step={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          payment={payments[step]}
          paids={paids}
          payments={payments}
          setPayments={setPayments}
          totalSteps={payments.length}
        />
      );
      case PAYMENT_TYPE_TARJETA_CREDITO: return (
        <CardPayment
          key={step}
          total={amount}
          type={paymentType}
          handlePayed={handlePayed}
          payed={payed}
          step={step}
          paids={paids}
          paymentStep={payments[step]}
          payments={payments}
          setPayments={setPayments}
          totalSteps={payments.length}
        />
      );
      case PAYMENT_TYPE_TARJETA_DEBITO: return (
        <CardPayment
          key={step}
          total={amount}
          type={paymentType}
          handlePayed={handlePayed}
          payed={payed}
          step={step}
          paids={paids}
          paymentStep={payments[step]}
          payments={payments}
          setPayments={setPayments}
          totalSteps={payments.length}
        />
      );
      case PAYMENT_TYPE_OTRO: return (
        <CardPayment
          key={step}
          total={amount}
          type="WALLET"
          handlePayed={handlePayed}
          payed={payed}
          step={step}
          paids={paids}
          paymentStep={payments[step]}
          payments={payments}
          setPayments={setPayments}
          totalSteps={payments.length}
        />
      );
      case PAYMENT_TYPE_WALLET: return (
        <WalletPayment
          key={step}
          total={amount}
          payed={payed}
          payment={payments[step]}
          handlePayed={handlePayed}
          payments={payments}
          setPayments={setPayments}
        />
      );
      case PAYMENT_TYPE_RAPPI: return (
        <RappiPayment
          key={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          paymentStep={payments[step]}
        />
      );
      case PAYMENT_TYPE_RAPPI_PAYLESS: return (
        <RappiPaylessPayment
          key={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          paymentStep={payments[step]}
        />
      );
      case PAYMENT_TYPE_RAPPI_TURBO: return (
        <RappiPayment
          key={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          paymentStep={payments[step]}
        />
      );
      case PAYMENT_TYPE_CORNERSHOP: return (
        <CornershopPayment
          key={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          paymentStep={payments[step]}
        />
      );
      case PAYMENT_TYPE_UBEREATS: return (
        <UberEatsPayment
          key={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          paymentStep={payments[step]}
        />
      );
      case PAYMENT_TYPE_PEDIDOSYA: return (
        <PedidosYaPayment
          key={step}
          total={amount}
          payed={payed}
          handlePayed={handlePayed}
          paymentStep={payments[step]}
        />
      );
      case PAYMENT_TYPE_MACH: return (
        <MachPayment
          amount={amount}
          orderId={orderId}
          handlePayed={handlePayed}
        />
      );
      case PAYMENT_TYPE_REDPAY: return (
        <RedpayPayment
          amount={amount}
          orderId={orderId}
          handlePayed={handlePayed}
        />
      );
    }
  };

  const totals = () => {
    if (!totalsPay.length) return 0;
    if (totalsPay.length > 1) {
      const total = totalsPay
        .filter((t) => t.status === STATUS_PENDIENTE || t.status === STATUS_APROBADO)
        .map((t) => t.amount)
        .reduce((a, b) => a + b);
      return thousandSeparator(total, '.');
    }
    return thousandSeparator(totalsPay[0].amount, '.');
  };

  const goToHome = () => {
    dispatch(resetCustomerType());
    dispatch(resetCustomer());
    dispatch(resetReturnType());
    dispatch(resetReturnProducts());
    dispatch(resetReturnCode());
    dispatch(resetDocumentType());
    dispatch(resetPayments());
    dispatch(resetApplyBulk());
    dispatch(resetBulk());
    dispatch(resetTotal());
    dispatch(resetGeneralDiscount());
    dispatch(resetCouponDiscount());
    dispatch(resetCoupon());
    dispatch(resetSubtotal());
    dispatch(resetBillingInfo());
    dispatch(resetCart());
    dispatch(resetCustomerOrderId());
    dispatch(resetCustomerOrderNumber());
    dispatch(markSmsSent(false));
    dispatch(setCustomerPoints(0));
    dispatch(resetRecommendedProduct());
    dispatch(resetAllDiscounts());
    dispatch(resetCartLoading());
    dispatch(resetReturnSmartPOSTransactionId());
    dispatch(resetOrderStatus());

    history.push('/inicio');
  };

  return (
    <div>
      {errorLoading ? (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography style={{ margin: '10px' }}>
            Ha ocurrido un error cargando la información
          </Typography>
          <button
            type="button"
            className="yellow-button"
            onClick={() => setReload((prev) => prev + 1)}
          >
            Recargar
          </button>
          <button
            type="button"
            className="yellow-button"
            style={{ marginTop: '50px', backgroundColor: '#dc143c' }}
            onClick={() => goToHome()}
          >
            Volver al inicio
          </button>
        </Box>
      ) : (
        <>
          {loading ? (
            <div>
              <CircularProgress style={{ color: 'var(--global--secondary--BackgroundColor' }} />
            </div>
          ) : (
            <div className="payment-sequence-container">
              {(applyBulk)
                ? (
                  <div>
                    Para compras mayoristas solo se admiten pagos en efectivo y transferencias.
                  </div>
                ) : ''}
              <h1>
                TOTAL: $
                {totals()}
              </h1>
              {displayPayment()}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PaymentSequence;
