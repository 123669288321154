/* eslint-disable no-await-in-loop */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
import { CircularProgress, Popover } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  resetCart,
  resetDocumentType,
  resetPayments,
  resetCustomerOrderId,
  setOrderStatus,
  resetBillingInfo,
  markSmsSent,
  resetRecommendedProduct,
  resetAllDiscounts,
  resetCartLoading,
  resetCustomerOrderNumber,
  resetOrderStatus,
} from '../../actions/customerActions';
import axios from '../../utils/axios';
import validateEmail from '../../utils/validator';
import CancelPay from './cancelPay/CancelPay';
import {
  REDELCOM_PROVIDER,
  // STATUS_PENDIENTE,
  STATUS_BILLING_PROCESSING,
  TRANSBANK_SMARTPOS_PROVIDER,
  STATUS_PAGANDO,
  STATUS_FINISHED,
  MERCADOPAGO_PROVIDER,
} from '../../utils/const';

function Final({ paids }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const orderId = useSelector((state) => state.customer.order_id);
  const orderStatus = useSelector((state) => state.customer.order_status);
  const customerType = useSelector((state) => state.customer.type);
  const billingType = useSelector((state) => state.customer.document_type);
  const billing = useSelector((state) => state.customer.billing);
  const customerEmail = useSelector((state) =>
    state.customer.user ? state.customer.user.email : null);
  const payments = useSelector((state) => state.customer.payment_types);
  const [email, setEmail] = useState(customerEmail || null);
  const [finished, setFinished] = useState(false);
  const [open, setOpen] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [billError, setBillError] = useState(null);
  const [billCreationError, setBillCreationError] = useState(null);
  const [billCreated, setBillCreated] = useState(false);
  const [billingSuccess, setBillingSuccess] = useState(false);
  const [generateBillSended, setGenerateBillSended] = useState(false);
  const customer = useSelector((state) => state.customer.user);
  const coupon = useSelector((state) => state.customer.coupon);
  const isMercadopagoMachine = useSelector((state) => state.session.isMercadopagoMachine);

  window.callPrinterWithResponse = async (resp) => {
    if (resp?.status === 0) {
      setBillError(null);
      setFinished(true);
      setBillCreationError(null);
      setBillCreated(false);
    } else if (resp?.status === 2) {
      const errorMessage = resp.error || 'Falta papel en la impresora';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    } else if (resp?.status === 9) {
      const errorMessage = resp.error || 'Batería muy baja para imprimir';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    } else {
      const errorMessage = resp.error || 'Ha ocurrido un error al imprimir';
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    }
    setLoading(false);
  };

  window.requestForPrinterResult = async (resp) => {
    if (resp?.response?.status === 0) {
      setBillError(null);
      setFinished(true);
      setBillCreationError(null);
      setBillCreated(false);
    } else if (resp?.response?.codeResponse === 2) {
      let errorMessage = resp.response?.glossResponse || 'Falta papel en la impresora';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    } else if (resp?.response?.codeResponse === 9) {
      let errorMessage = resp.response?.glossResponse || 'Batería muy baja para imprimir';
      errorMessage += `(Cod ${resp.response.codeResponse})`;
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    } else {
      let errorMessage = resp?.response?.glossResponse || 'Ha ocurrido un error al imprimir';
      if (resp?.response?.codeResponse) {
        errorMessage += `(Cod ${resp.response.codeResponse})`;
      }
      const key = enqueueSnackbar(errorMessage, {
        variant: 'error',
        onClick: () => {
          closeSnackbar(key);
        },
      });
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    }
    setLoading(false);
  };

  useEffect(() => {
    const pt = payments.map((p) => p.paymentType.toLowerCase());
    setPaymentTypes(pt);
  }, [payments]);

  const generateBill = async () => {
    const billData = {
      order_id: orderId,
      billing_type: billingType,
      billing,
    };

    if (billData.order_id) {
      try {
        if (coupon && customer) {
          await axios.post('/api/coupon/redeem', {
            customerId: customer?.id,
            couponId: coupon.coupon?.id,
          });
        }

        // Change order status to avoid bill duplication
        if (orderStatus === STATUS_PAGANDO) {
          dispatch(setOrderStatus(STATUS_BILLING_PROCESSING));
        }

        setGenerateBillSended(true);
        const response = await axios.post('/api/payments/bill', billData);
        if (response.status === 200) {
          if (response.data.success) {
            setBillCreationError(null);
            setBillCreated(true);
            dispatch(setOrderStatus(STATUS_FINISHED));
            const { billingSuccess: billSuccess = true } = response.data?.data || {};
            setBillingSuccess(billSuccess);
          } else {
            if (response.data.code === 400) {
              setBillCreationError('Error generando boleta. Revisar stock en BSale.');
              return;
            }
            setBillCreationError('Error generando boleta. Por favor intente nuevamente.');
          }
        } else {
          setBillCreationError('Error generando boleta. Por favor intente nuevamente.');
        }
      } catch (err) {
        const errorMessage = err.response?.data?.message || 'Error generando boleta. Por favor intente nuevamente.';
        setBillCreationError(errorMessage);
      }
    }
  };

  const getBilling = async () => {
    let billingFinished = false;
    try {
      while (!billingFinished) {
        const billResponse = await axios.get(`/api/billing/order/${orderId}`);
        if (billResponse.data.data.sii_code) {
          billingFinished = true;
          setBillCreationError(null);
          setBillCreated(true);
          dispatch(setOrderStatus(STATUS_FINISHED));
        } else if (billResponse.data.data['Order.status']?.includes('BSALE ERROR')) {
          billingFinished = true;
          setBillCreationError('Error generando boleta. Por favor intente nuevamente.');
        }
        await new Promise((resolve) => setTimeout(resolve, 1500));
      }
    } catch (error) {
      setBillError('Error cargando la boleta');
    }
  };

  useEffect(() => {
    if (orderStatus === STATUS_BILLING_PROCESSING && !generateBillSended) {
      getBilling();
    } else if (orderStatus !== STATUS_FINISHED && !generateBillSended) {
      generateBill();
    } else if (orderStatus === STATUS_FINISHED) {
      setBillCreationError(null);
      setBillCreated(true);
    }
  }, [orderStatus, generateBillSended]);

  const resetAll = () => {
    dispatch(resetCart());
    dispatch(resetCustomerOrderId());
    dispatch(resetCustomerOrderNumber());
    dispatch(resetDocumentType());
    dispatch(resetPayments());
    dispatch(resetBillingInfo());
    dispatch(markSmsSent(false));
    dispatch(resetRecommendedProduct());
    dispatch(resetAllDiscounts());
    dispatch(resetCartLoading());
    dispatch(resetOrderStatus());
  };

  const createBill = async (format) => {
    setLoading(true);
    const printErr = () => {
      if (format.toLowerCase() === 'email') {
        setBillError('Error enviando boleta por email. Por favor intente nuevamente.');
      } else {
        setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
      }
    };
    try {
      let provider = '';
      let providerVersion = '';
      if (window.AppPagoSDK) {
        provider = TRANSBANK_SMARTPOS_PROVIDER;
        if (window.HARDWARESDK) {
          providerVersion = 'pro';
        } else {
          providerVersion = 'normal';
        }
      } else {
        provider = REDELCOM_PROVIDER;
      }
      const response = await axios.post('/api/payments/bill/print', {
        order_id: orderId,
        format,
        email,
        provider,
        provider_version: providerVersion,
        billing_success: billingSuccess,
      });
      if (response.status === 200) {
        if (response.data.success) {
          if (provider === TRANSBANK_SMARTPOS_PROVIDER && format.toLowerCase() !== 'email') {
            let { printFormat = [] } = response.data.data;
            if (window.HARDWARESDK) { // New sdk for printing
              window.HARDWARESDK.requestForPrinter(JSON.stringify(printFormat));
            } else {
              printFormat = JSON.stringify({ lines: printFormat });
              window.AppPagoSDK.callPrinterWith(printFormat);
            }
          } else {
            setLoading(false);
            setBillError(null);
            setFinished(true);
            setBillCreationError(null);
            setBillCreated(false);
          }
        } else {
          printErr();
        }
      } else {
        printErr();
      }
    } catch (err) {
      printErr();
    } finally {
      setLoading(false);
    }
  };

  const printMercadoPagoMachine = async () => {
    setLoading(true);

    const successCallback = (result) => {
      console.log('successCallback', result);
      setLoading(false);
      setBillError(null);
      setFinished(true);
      setBillCreationError(null);
      setBillCreated(false);
    };

    const errorCallback = (error) => {
      console.log('errorCallback', error);
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
      setLoading(false);
    };

    const printCallback = (result, error) => {
      if (result === 'success') {
        successCallback(result);
      } else {
        errorCallback(error);
      }
      setLoading(false);
    };

    try {
      const response = await axios.post('/api/payments/bill/print', {
        order_id: orderId,
        format: 'impresa',
        billing_success: billingSuccess,
        provider: MERCADOPAGO_PROVIDER,
      });
      const dte = response.data.data.printFormat;
      // eslint-disable-next-line no-undef
      launchCustomTag(dte, printCallback);
    } catch (err) {
      console.log('error printMercadoPagoMachine', err);
      setBillError('Error imprimiendo boleta. Por favor intente nuevamente.');
    } finally {
      setLoading(false);
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setEmailError('');
    if (validateEmail(email)) {
      await createBill('email');
      setOpen(false);
    } else {
      setEmailError('Email no válido');
    }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !loading) {
      handleSubmit();
    }
  };

  const popoverContent = () => (
    <div className="final-popover-container">
      <div className="popover-info-message">
        <p>Ingrese su correo</p>
        <input
          id="email"
          type="text"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="entry-confirmation-buttons">
        <button
          disabled={loading}
          className={
            loading ? 'accept-entry-button deactivated' : 'accept-entry-button'
          }
          type="submit"
          onClick={() => handleSubmit()}
        >
          Aceptar
        </button>
        <button
          className="notmember-entry-button"
          type="submit"
          onClick={() => setOpen(false)}
        >
          Cancelar
        </button>
      </div>
      {emailError !== '' && <p className="error-message">{emailError}</p>}

      <div
        style={{
          margin: '0 auto',
          paddingTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          visibility: loading && open ? 'visible' : 'hidden',
        }}
      >
        <CircularProgress
          style={{ color: 'var(--global--secondary--BackgroundColor' }}
        />
      </div>
    </div>
  );

  const goTo = (url) => {
    resetAll();
    history.push(url);
  };

  const handleRetryBilling = async () => {
    setBillCreationError(null);
    generateBill();
  };

  return (
    <div>
      {(!orderId) ? (
        <Redirect to="/inicio" />
      )
        : (
          <div className="final-payment-result">
            {finished ? (
              <div>
                <h1>Compra Finalizada</h1>
                <p>Pago(s) realizado(s) con éxito!</p>
                <div className="final-payment-buttons">
                  <button
                    type="button"
                    className="yellow-button"
                    onClick={() => goTo('/inicio')}
                  >
                    Volver al inicio
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {billCreated ? (
                  <div>
                    {billingSuccess ? (
                      <>
                        <h1>¿Cómo desea su documento?</h1>
                        <div className="final-payment-buttons">
                          {customerType !== 'rappi' && (
                            <button
                              type="button"
                              className="yellow-button"
                              disabled={loading}
                              onClick={() => setOpen(true)}
                            >
                              Enviar por Email
                            </button>
                          )}
                          {(!paymentTypes.includes('transferencia') && !paymentTypes.includes('wallet')) && (
                            <button
                              type="button"
                              className={
                                loading ? 'yellow-button deactivated' : 'yellow-button'
                              }
                              disabled={loading}
                              onClick={() => {
                                if (isMercadopagoMachine) {
                                  printMercadoPagoMachine();
                                } else {
                                  createBill('impresa');
                                }
                              }}
                            >
                              Imprimir
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="final-payment-buttons">
                        <button
                          type="button"
                          className="yellow-button"
                          disabled={loading}
                          onClick={() => setOpen(true)}
                        >
                          Enviar por Email
                        </button>
                      </div>
                    )}

                    {billError ? (
                      <p style={{ color: 'red' }}>{billError}</p>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <h1>Generando Documento</h1>
                    {billCreationError ? null : (
                      <CircularProgress
                        style={{ color: 'var(--global--secondary--BackgroundColor' }}
                      />
                    )}
                    {billCreationError ? (
                      <div>
                        <p style={{ color: 'red' }}>{billCreationError}</p>
                        <div className="tabs-payment">
                          <button
                            type="button"
                            className="yellow-button"
                            onClick={() => handleRetryBilling()}
                          >
                            Reintentar
                          </button>
                        </div>
                        <div className="tabs-payment">
                          <CancelPay paids={paids} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            )}
            <Popover
              open={open}
              onClose={() => setOpen(false)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
            >
              {popoverContent()}
            </Popover>
            <div
              style={{
                margin: '0 auto',
                paddingTop: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                visibility: loading && !open ? 'visible' : 'hidden',
              }}
            >
              <CircularProgress
                style={{ color: 'var(--global--secondary--BackgroundColor' }}
              />
            </div>
          </div>
        )}
    </div>
  );
}

export default Final;
